.container,
.text-container {
	margin: 0 auto;
	position: relative;
	padding: 0 20px;
}

.text-container {
	max-width: 1000px;
}

.container {
	max-width: 1000px;
}

video {
	width: 100%    !important;
	height: auto   !important;
}

header {
	color: #fff;
	padding: 20px 0;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;

	a {
		color: #fff;
		text-decoration: none;
		z-index: 1;
		position: relative;

		&:hover {
			text-decoration: none;
		}
	}

	.company-name {
		font-size: 1.7em;
	}
}

.list-posts-image {
	max-width: 180px;
	max-height: 180px;
	border-radius: 8px;
}

.content {
	background: #fff;
	padding: 1px 0 0 0;
	position: relative;
}

.hero-casual {
	padding: 175px 0 0 0;
}

.screenshot{
	height: auto;
	display: block;
	margin: 0 auto;
	border-radius: 7px;
	box-shadow: 0px 1px 35px 1px #cccccc;
}

section {
	padding: 35px 0;
	background: #fff;
	margin-top: -2px;

	&.hero {
		color: #111;
		background: $secondary-background-color;
		text-align: center;
		margin-top: -100px;
		padding-top: 300px;

		h2 {
			font-size: 3.5rem;
		}

		p {
			font-size: 1.6rem;
			color: $body-text;
		}

		a:not(.button) {
			color: $brand-color;
			font-style: italic;
			text-decoration: none;
		}
	}

	&.alternate {
		padding: 100px 0;
		background: $secondary-background-color;
	}

	&.pattern-section {
		padding: 100px 0;
		background: $secondary-background-color url($pattern);
	}
}

.button {
	padding: 13px 38px;
	border-radius: 6px;
	display: inline-block;
	color: $brand-color;
	background: #fff;
	text-decoration: none;
	font-weight: 500;
	cursor: pointer;
	margin: 20px 0 0 0;
	text-decoration: none !important;
	box-shadow: 0px 1px 35px 1px #cccccc;
	transition: 0.3s ease-in-out;

	&:hover {
		background: $brand-color;
		color: #fcfcfc;
		transition: 0.3s ease-in-out;
	}

	&:active {
		opacity: 1;
		background: rgba(255, 255, 255, 0.1);
		color: #fff;
		transform: translateY(1px);
	}
}

.halves {
	display: flex;
	flex-flow: wrap;
	align-items: center;

	> div {
		flex: 1 0 100%;
	}

	@media #{$mid-point} {
		> div {
			flex: 1;
		}

		> div + div {
			padding-left: 20px;
			margin: 0;
		}
	}
}

.aligned-top {
	align-items: flex-start;
}

.image-grid {
	padding: 0;
	list-style: none;
	display: flex;
	flex-flow: wrap;
	text-align: center;
	margin: 0 -20px;

	li {
		padding: 0;
		box-sizing: border-box;
		width: 100%;
		position: relative;
		overflow: hidden;
		margin: 20px;
		border-radius: 5px;
		text-align: left;
		border: 1px solid $brand-color;
		line-height: 0;

		@media #{$tablet} {
			flex: 1 1 50%;
		}

		@media #{$desktop} {
			flex: 1 1 30%;
		}

		img {
			min-width: 100%;
		}

		> a:hover {
			opacity: 0.9;
		}
	}

	.square-image {
		height: 320px;

		img {
			min-height: 100%;
			width: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	.details {
		line-height: 1.2;
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		background: rgba(0, 0, 0, 0.5);
		padding: 20px;
	}

	.name {
		font-size: 1.3rem;
		color: $brand-color;
	}

	.position {
		margin-top: 5px;
		color: #fff;
	}

	.filler {
		border: 0;
	}
}

.image-grid-floating {
	padding: 0;
	list-style: none;
	display: flex;
	flex-flow: wrap;
	text-align: center;
	margin: 0 -20px;

	li {
		padding: 0;
		box-sizing: border-box;
		width: 100%;
		margin: 20px;
		text-align: left;
		line-height: 0;

		@media #{$tablet} {
			flex: 1 1 50%;
		}

		@media #{$desktop} {
			flex: 1 1 30%;
		}

		img {
			min-width: 100%;
			box-shadow: 0px 2px 25px 1px #a3a3a3;
			border-radius: 9px;
			background-color: #fefefe;
		}

		> a:hover {
			text-decoration: none;
		}
	}

	.square-image {
		height: 320px;

		img {
			min-height: 100%;
			width: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	.details {
		line-height: 1.2;
		padding: 20px;
	}

	.name {
		font-size: 1.5rem;
		color: $brand-color;
	}

	.position {
		font-size: 1.2rem;
		margin-top: 5px;
		color: $body-text;
	}

	.filler {
		border: 0;
	}
}

.image-grid-plain {
	padding: 0;
	list-style: none;
	display: flex;
	flex-flow: wrap;
	text-align: center;
	margin: 0 -20px;

	li {
		padding: 0;
		box-sizing: border-box;
		width: 100%;
		margin: 20px;
		text-align: left;
		line-height: 0;

		@media #{$tablet} {
			flex: 1 1 50%;
		}

		@media #{$desktop} {
			flex: 1 1 30%;
		}

		img {
			min-width: 100%;
			background-color: rgba(255, 255, 255, 0.0);
		}

		> a:hover {
			text-decoration: none;
		}
	}

	.square-image {
		height: 320px;

		img {
			min-height: 100%;
			width: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translateX(-50%) translateY(-50%);
		}
	}

	.details {
		line-height: 1.2;
		padding: 20px;
	}

	.name {
		font-size: 1.5rem;
		color: $brand-color;
	}

	.position {
		font-size: 1.2rem;
		margin-top: 5px;
		color: $body-text;
	}

	.filler {
		border: 0;
	}
}

.image-list,
.image-list li {
	list-style: none;
	margin: 0;
	padding: 0;
	text-align: center;
}

.patterned {
	background: $secondary-background-color url($pattern);

	&,
	p {
		color: $body-text;
	}
}

.app-store-badge {
	display: flex;
	justify-content: center;
	flex-direction: row;
	padding-top: 24px;

	a {
		margin-right: 20px;
	}

	img {
		height: 55px;
	}
}

.author {
	max-width: 300px;
	max-height: 300px;

	img {
		max-width: 300px;
		max-height: 300px;
	}
}

.screenshots {
	display: flex;
    align-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;

	img {
		flex-grow: 1;
		width: 99%;
		height: 99%;
		margin: 1%;
		border-color: #ddd;
		border-style: solid;
	}

	@media (min-width: 480px) {
		img {
			flex-grow: 1;
			width: 45%;
			height: 45%;
			margin: 1%;
			border-color: #ddd;
			border-style: solid;
		}
	}

	@media (min-width: 768px) {
		img {
			flex-grow: 1;
			width: 30%;
			height: 30%;
			margin: 1%;
			border-color: #ddd;
			border-style: solid;
		}
	}

	@media (min-width: 960px) {
		img {
			flex-grow: 1;
			width: 15%;
			height: 15%;
			margin: 1%;
			border-color: #ddd;
			border-style: solid;
		}
	}
}

.screenshots-landscape {
	display: flex;
    align-content: space-around;
	flex-direction: row;
	flex-wrap: wrap;

	img {
		flex-grow: 1;
		width: 99%;
		height: 99%;
		margin: 1%;
		border-color: #ddd;
		border-style: solid;
	}

	@media (min-width: 768px) {
		img {
			flex-grow: 1;
			width: 45%;
			height: 45%;
			margin: 1%;
			border-color: #ddd;
			border-style: solid;
		}
	}

	@media (min-width: 960px) {
		img {
			flex-grow: 1;
			width: 30%;
			height: 30%;
			margin: 1%;
			border-color: #ddd;
			border-style: solid;
		}
	}
}
